<template>
    <section id="hero">
        <base-jumbotron
                :src="src"
        >
            <v-container
                    fill-height
            >
                <v-layout
                        align-center
                        justify-center
                >
                    <base-title>
                        {{title}}
                    </base-title>
                </v-layout>
            </v-container>
        </base-jumbotron>
    </section>
</template>

<script>
    export default {
        name:'Hero',
        props:{
            title: {
                type: String,
                default: 'Post Title'
            },
            src: {
                type: URL,
                default: './images/p1.jpg'
            }
        }
    }
</script>
